import React from 'react';

const ComingSoonIndicator = () => (
  <div style={{
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'top',
    letterSpacing: '.2px',
    fontSize: '7px',
    lineHeight: '11px',
    fontWeight: 400,
    padding: '0 4px',
    textTransform: 'uppercase',
    color: '#000',
    border: '1px solid',
    borderRadius: '2px',
    marginLeft: '10px',
    // marginRight: '0',
    marginBottom: '-6px',
  }}>coming soon</div>
);

export default ComingSoonIndicator;
